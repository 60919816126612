/* Import the custom font */
@import url('./fonts/fonts.css');
/* 
body {
  font-family: 'Graphik Regular', 'Graphik', sans-serif;
} */


body {
  font-family: 'Arial', sans-serif;
}

.paddingAll {
  padding: 10px 10px 10px 10px;
}

.priceTitle {
  font-weight: 600;
}

.priceSubTitle {
  font-size: 14px;
}

.iconStyle {
  margin-top: 20px !important;
  margin-left: 20px !important;
}

.sideNavItem {
  background-color: transparent;
  border-radius: 10px;
  color: #62626E;
}

@keyframes selected-item-animation {
  0% {
    background-color: transparent;
    /* Start with a transparent background */
    /* transform: scale(1); */
    /* Start with normal size */
  }

  50% {
    background-color: rgba(240, 75, 85, 0.3);
    /* Midway background color */
    /* transform: scale(1.05); */
    /* Scale up slightly at the midpoint */
  }

  100% {
    background-color: rgba(240, 75, 85, 0.3);
    /* End background color */
    /* transform: scale(1); */
    /* Return to normal size */
  }
}


.sideNavItemSelected {
  background-color: rgba(240, 75, 85, 0.09) !important;
  transition: background-color 0.3s ease;
  animation: selected-item-animation 0.5s ease;
  color: rgba(240, 75, 85, 0.89) !important;
  font-weight: "bold" !important;
}

.nestedBulletedList {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #62626E;
  /* transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
}

.nestedBulletedListSelected {
  width: 10px;
  height: 10px;
  background-color: rgba(240, 75, 85, 0.89);
}

/* Track */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Thumb */
::-webkit-scrollbar-thumb {
  background: #AF3241;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6E2332;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle when the scrollbar is active */
::-webkit-scrollbar-thumb:active {
  background: #6E2332;
}



.custom-time-input input[type="time"]::-webkit-datetime-edit {
  /* Apply your custom styles to the time input dropdown options */
  background-color: "#6E2332";
  color: "#f1f1f1";
  /* Add other styles as needed */
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.cyreen_input_errors {
  /* font-weight: bold; */
  color: #d32f2f;
  font-size: 12px;
}

/* Remove background color and bold text from the selected week number */
.react-datepicker__week-number.react-datepicker__week-number--selected {
  background-color: transparent !important;
  font-weight: normal !important;
  color: #ccc !important;
}

.react-datepicker__week-number--keyboard-selected {
  background-color: transparent !important;
  font-weight: normal !important;
  color: #ccc !important;
}

.Toastify__toast-container--top-center {
  width: 500px !important;
}